
import { Attribute } from '@/utils/variants'
import { defineComponent, ref, PropType } from 'vue'
import AttributeCatalogOptionDialog from './AttributeCatalogOptionDialog.vue'
import {
  OptionType,
  OptionAction,
  UpdateAttribute,
} from './CatalogVariants.vue'

export default defineComponent({
  components: { AttributeCatalogOptionDialog },
  props: {
    options: {
      type: Array as PropType<Attribute[]>,
      default: () => [],
    },
    type: {
      type: String as PropType<OptionType>,
      default: 'color',
    },
  },
  setup(props, { emit }) {
    const optionList = ref<Attribute[]>([...props.options])

    const handleClose = (optionName: string) => {
      const removeItem = optionList.value.find(
        option => option.name === optionName
      )
      if (removeItem) {
        const update: UpdateAttribute = {
          optionType: props.type,
          action: OptionAction.remove,
          option: removeItem,
        }
        emit('callback', update)
      }
    }

    const attributeCatalogOptionDialog =
      ref<InstanceType<typeof AttributeCatalogOptionDialog>>()
    const showDialogAttribute = () => {
      attributeCatalogOptionDialog.value?.toggle()
    }

    const onAfterSubmitAttribute = (attribute: Attribute) => {
      const update: UpdateAttribute = {
        optionType: props.type,
        action: OptionAction.add,
        option: attribute,
      }
      emit('callback', update)
    }

    return {
      props,
      optionList,
      handleClose,
      showDialogAttribute,
      attributeCatalogOptionDialog,
      onAfterSubmitAttribute,
    }
  },
})
