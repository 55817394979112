
import CloseButton from '@/components/common/buttons/CloseButton.vue'
import { defineComponent, ref } from 'vue'
import { required } from '@/utils/formRules'
import SaveButton from '@/components/common/buttons/SaveButton.vue'
import { useNotification } from '@/composables/useNotification'
import { Variant } from '@/interfaces/catalog'

export default defineComponent({
  components: { CloseButton, SaveButton },
  props: {
    title: {
      type: String,
      required: true,
      default: 'Modal Dialog',
    },
    designs: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const form = ref()
    const isLoading = ref(true)
    const dialogVisible = ref(false)

    const variant = ref<any>({})

    const rules = ref({
      catalogVariantCode: required('Variant code'),
    })

    const setIsLoading = (value: boolean) => {
      isLoading.value = value
    }

    const toggle = (v: Variant) => {
      setIsLoading(true)
      variant.value = v
      dialogVisible.value = true
      setIsLoading(false)
    }

    const onClose = () => {
      clear()
      dialogVisible.value = false
    }

    const { success, error } = useNotification()
    const onSave = () => {
      form.value.validate(async (isValid: boolean) => {
        if (isValid) {
          try {
            onClose()
          } catch (e) {
            error('Error when save')
            console.log(e)
          }
        } else {
          return false
        }
      })
    }

    const clear = () => {
      //   variant.name = ''
      //   variant.code = ''
      //   variant.cost = 0
    }
    return {
      props,
      dialogVisible,
      toggle,
      onClose,
      clear,
      rules,
      form,
      onSave,
      setIsLoading,
      isLoading,
      variant,
    }
  },
})
