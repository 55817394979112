
import { defineComponent, computed, reactive, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import PageTitle from '@/components/common/PageTitle.vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import CatalogInfomationCard from '@/components/catalogs/update-components/CatalogInfomationCard.vue'
import userCatalogRepositories from '@/repositories/userCatalogRepositories'
import CatalogVariants from '@/components/catalogs/CatalogVariants.vue'
import CloseButton from '@/components/common/buttons/CloseButton.vue'
import SaveButton from '@/components/common/buttons/SaveButton.vue'

export default defineComponent({
  components: {
    PageTitle,
    sectionLayoutContent,
    CatalogInfomationCard,
    CatalogVariants,
    SaveButton,
    CloseButton,
  },
  props: {},
  setup() {
    const route = useRoute()
    const router = useRouter()
    const catalog = reactive<any>({})
    const isLoading = ref(true)

    const breadcrumbs = [
      {
        text: 'Catalog',
        link: '',
        params: null,
      },
      {
        text: 'Edit',
        link: '',
        params: null,
      },
    ]
    const content = computed(() => {
      return { breadcrumbs }
    })

    const { getCatalog, update } = userCatalogRepositories()
    const fetchCatalog = async () => {
      setLoading(true)
      const { status, data } = await getCatalog(route.params.id)
      data.categories = data.categories.map((item: any) => item._id)
      if (status === 200) {
        Object.assign(catalog, data)
      }
      setLoading(false)
    }

    onMounted(async () => {
      await fetchCatalog()
    })

    const setLoading = (value: boolean) => {
      isLoading.value = value
    }

    const updateCatalog = async () => {
      setLoading(true)
      // code'
      delete catalog.description
      delete catalog.sizeGuide
      // await update(catalog)

      setLoading(false)
    }

    return {
      content,
      breadcrumbs,
      fetchCatalog,
      route,
      router,
      catalog,
      isLoading,
      setLoading,
      updateCatalog,
    }
  },
})
