
import CloseButton from '@/components/common/buttons/CloseButton.vue'
import { defineComponent, reactive, ref } from 'vue'
import { required } from '@/utils/formRules'
import SaveButton from '../common/buttons/SaveButton.vue'
import { useNotification } from '@/composables/useNotification'
import { Attribute } from '@/utils/variants'

export default defineComponent({
  components: { CloseButton, SaveButton },
  props: {
    title: {
      type: String,
      required: true,
      default: 'Modal Dialog',
    },
  },
  setup(props, { emit }) {
    const dialogVisible = ref(false)
    const form = ref()

    const state = reactive({
      form: {
        name: '',
        code: '',
        cost: 0,
      },
    })

    const rules = ref({
      name: required('name'),
    })

    const { error } = useNotification()
    const onSave = () => {
      form.value.validate(async (isValid: boolean) => {
        if (isValid) {
          try {
            const attribute: Attribute = {
              ...state.form,
              value: state.form.name,
            }

            emit('sumitedAttribute', attribute)
            onClose()
          } catch (e) {
            error('Error when save')
            console.log(e)
          }
        } else {
          return false
        }
      })
    }

    const toggle = () => {
      dialogVisible.value = true
    }

    const onClose = () => {
      clear()
      dialogVisible.value = false
    }

    const clear = () => {
      state.form.name = ''
      state.form.code = ''
      state.form.cost = 0
    }

    return {
      props,
      dialogVisible,
      toggle,
      onClose,
      clear,
      state,
      rules,
      form,
      onSave,
    }
  },
})
