
import { defineComponent } from 'vue'
import {
  getPublishStatusName,
  getPublishStatusTagColor,
  PUBLISH_STATUS_ARR,
} from '@/utils/catalog'

export default defineComponent({
  components: {},
  props: {},
  setup() {
    return {
      getPublishStatusName,
      getPublishStatusTagColor,
      PUBLISH_STATUS_ARR,
    }
  },
})
