
import { defineComponent, onMounted, ref } from 'vue'
import useCategoryCatalogRepositories from '@/repositories/useCategoryCatalogReponsitories'

export default defineComponent({
  components: {},
  props: {},
  setup() {
    const loading = ref(true)
    const categories = ref()

    const { fetchAllCategories } = useCategoryCatalogRepositories()

    const fetchAll = async () => {
      const data: any = await fetchAllCategories()
      categories.value = data ?? []
      loading.value = false
    }

    onMounted(() => {
      fetchAll()
    })

    return { loading, fetchAll, categories }
  },
})
