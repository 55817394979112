import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_close_button = _resolveComponent("close-button")!
  const _component_save_button = _resolveComponent("save-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dialogVisible) = $event)),
    title: _ctx.title,
    width: "35%"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_close_button, {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onClose()))
      }),
      _createVNode(_component_save_button, {
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onSave()))
      })
    ]),
    default: _withCtx(() => [
      (_ctx.dialogVisible)
        ? (_openBlock(), _createBlock(_component_el_form, {
            key: 0,
            ref: "form",
            model: _ctx.state.form,
            rules: _ctx.rules,
            "label-width": "20%",
            class: "font-bold"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "Name:",
                prop: "name"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.state.form.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.form.name) = $event)),
                    clearable: "",
                    maxlength: 60,
                    "show-word-limit": "",
                    onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.onSave()), ["enter"]))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "Code:",
                prop: "code",
                class: "mt-5"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.state.form.code,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.form.code) = $event)),
                    clearable: "",
                    maxlength: 60,
                    "show-word-limit": ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "Cost:",
                prop: "cost",
                class: "mt-5"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input_number, {
                    modelValue: _ctx.state.form.cost,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.form.cost) = $event)),
                    min: 0,
                    max: 10000,
                    "controls-position": "right",
                    clearable: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}