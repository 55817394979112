
import { required } from '@/utils/formRules'
import { defineComponent, reactive, ref } from 'vue'
import SelectCatalogCategory from '@/components/catalogs/update-components/SelectCatalogCategory.vue'
import SelectCatalogTags from '@/components/catalogs/update-components/SelectCatalogTags.vue'
import SelectCatalogPublishStatus from './SelectCatalogPublishStatus.vue'
import { RefreshLeft } from '@element-plus/icons'
import Editor from '@/pages/admin/catalog/components/Editor.vue'

export default defineComponent({
  components: {
    SelectCatalogCategory,
    SelectCatalogTags,
    SelectCatalogPublishStatus,
    RefreshLeft,
    Editor,
  },
  props: {
    catalog: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup(props) {
    const activeName = ref('description')

    const state = reactive({
      form: {
        name: '',
      },
    })

    const callbackEditor = (html: string, catalog: any, fieldName: string) => {
      catalog[fieldName] = html
    }

    const formRef = ref()
    const rules = ref({
      name: required('Name'),
      productCode: required('Code'),
    })

    return { props, state, rules, formRef, activeName, callbackEditor }
  },
})
