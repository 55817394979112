
import { defineComponent } from 'vue'
import { ArrowDown } from '@element-plus/icons'

export default defineComponent({
  components: { ArrowDown },
  props: {
    seletedNums: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    return { props }
  },
})
