import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex justify-between items-center my-2" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "flex items-center justify-start" }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 4 }
const _hoisted_11 = { key: 5 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CatalogOptions = _resolveComponent("CatalogOptions")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_ActionsButton = _resolveComponent("ActionsButton")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_EditRowButton = _resolveComponent("EditRowButton")!
  const _component_DeleteRowButton = _resolveComponent("DeleteRowButton")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_EditVariantDialog = _resolveComponent("EditVariantDialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_el_row, { class: "w-full" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 24 }, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_el_form, {
                    class: "font-bold",
                    "label-width": "10%"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, { label: "Colors:" }, {
                        default: _withCtx(() => [
                          (!_ctx.isOptionReloading)
                            ? (_openBlock(), _createBlock(_component_CatalogOptions, {
                                key: 0,
                                options: _ctx.colorList,
                                type: "color",
                                onCallback: _ctx.onChangeCatalogOption
                              }, null, 8, ["options", "onCallback"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_form_item, { label: "Sizes:" }, {
                        default: _withCtx(() => [
                          (!_ctx.isOptionReloading)
                            ? (_openBlock(), _createBlock(_component_CatalogOptions, {
                                key: 0,
                                options: _ctx.sizeList,
                                type: "size",
                                onCallback: _ctx.onChangeCatalogOption
                              }, null, 8, ["options", "onCallback"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 512), [
                    [_directive_loading, _ctx.isOptionReloading]
                  ])
                ]),
                _: 1
              }),
              false
                ? (_openBlock(), _createBlock(_component_el_col, {
                    key: 0,
                    span: 24,
                    class: "my-2 flex justify-end"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        modelValue: _ctx.filterColors,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterColors) = $event)),
                        placeholder: "Filter by color",
                        multiple: "",
                        filterable: "",
                        "allow-create": "",
                        clearable: "",
                        onChange: _ctx.onSelectFilter
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colorList, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item.name,
                              label: item.name,
                              value: item.name
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "onChange"]),
                      _createVNode(_component_el_select, {
                        modelValue: _ctx.filterSizes,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterSizes) = $event)),
                        placeholder: "Filter by size",
                        multiple: "",
                        filterable: "",
                        "allow-create": "",
                        clearable: "",
                        onChange: _ctx.onSelectFilter,
                        class: "ml-2"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sizeList, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item.name,
                              label: item.name,
                              value: item.name
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "onChange"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_el_col, { span: 24 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_ActionsButton, { seletedNums: 100 }),
                    _createVNode(_component_el_checkbox, {
                      modelValue: _ctx.editMode,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editMode) = $event)),
                      label: "Edit mode"
                    }, null, 8, ["modelValue"])
                  ]),
                  _createVNode(_component_el_table, {
                    data: _ctx.catalogVariants,
                    lazy: "",
                    stripe: "",
                    fit: "",
                    "header-cell-class-name": "custom-text-mini",
                    "cell-class-name": "font-light custom-text-mini"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_table_column, { type: "selection" }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
                        return (_openBlock(), _createBlock(_component_el_table_column, {
                          key: column.dataIndex,
                          prop: column.dataIndex,
                          label: column.title,
                          "tooltip-effect": column.dataIndex,
                          "min-width": column.minWidth
                        }, {
                          default: _withCtx((scope) => [
                            (column.dataIndex === 'catalogVariantCode')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                  (_ctx.editMode)
                                    ? (_openBlock(), _createBlock(_component_el_input, {
                                        key: 0,
                                        size: "small",
                                        modelValue: scope.row[column.dataIndex],
                                        "onUpdate:modelValue": ($event: any) => ((scope.row[column.dataIndex]) = $event)
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                    : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(scope.row[column.dataIndex]), 1))
                                ]))
                              : (column.dataIndex === 'color')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                    _createElementVNode("div", _hoisted_6, [
                                      (scope.row[column.dataIndex])
                                        ? (_openBlock(), _createElementBlock("div", {
                                            key: 0,
                                            class: "w-4 h-4 bg-color-variant mr-1",
                                            style: _normalizeStyle(`--color:${scope.row[column.dataIndex].value}`)
                                          }, null, 4))
                                        : (_openBlock(), _createElementBlock("div", {
                                            key: 1,
                                            class: "w-4 h-4 bg-color-variant mr-1",
                                            style: _normalizeStyle(`--color:${scope.row[column.dataIndex].name}`)
                                          }, null, 4)),
                                      _createElementVNode("div", null, _toDisplayString(_ctx.displayAttribute(scope.row[column.dataIndex])), 1)
                                    ])
                                  ]))
                                : (column.dataIndex === 'size')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.displayAttribute(scope.row[column.dataIndex])), 1))
                                  : (column.dataIndex === 'inStock')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                        (_ctx.editMode)
                                          ? (_openBlock(), _createBlock(_component_el_select, {
                                              key: 0,
                                              size: "small",
                                              modelValue: scope.row[column.dataIndex],
                                              "onUpdate:modelValue": ($event: any) => ((scope.row[column.dataIndex]) = $event)
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_option, {
                                                  label: "In Stock",
                                                  value: true
                                                }),
                                                _createVNode(_component_el_option, {
                                                  label: "Out of Stock",
                                                  value: false
                                                })
                                              ]),
                                              _: 2
                                            }, 1032, ["modelValue", "onUpdate:modelValue"]))
                                          : (_openBlock(), _createElementBlock("p", _hoisted_9, [
                                              _createVNode(_component_el_tag, {
                                                size: "small",
                                                type: `${scope.row.inStock ? 'success' : 'danger'}`
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.displayInStockStatus(scope.row[column.dataIndex])), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["type"])
                                            ]))
                                      ]))
                                    : (column.dataIndex === 'cost')
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.formatterDollars(scope.row[column.dataIndex])), 1))
                                      : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(scope.row[column.dataIndex]), 1))
                          ]),
                          _: 2
                        }, 1032, ["prop", "label", "tooltip-effect", "min-width"]))
                      }), 128)),
                      _createVNode(_component_el_table_column, { label: "Design" }, {
                        default: _withCtx((scope) => [
                          _createVNode(_component_el_table_column, {
                            label: "Qty",
                            "min-width": "8"
                          }, {
                            default: _withCtx(() => [
                              (scope.row.designs?.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(scope.row.designs?.length), 1))
                                : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                    _createElementVNode("p", null, _toDisplayString(_ctx.designs?.length), 1)
                                  ]))
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_el_table_column, {
                            label: "Type",
                            "min-width": "8"
                          }, {
                            default: _withCtx(() => [
                              (scope.row.designs?.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.designs, (item) => {
                                      return (_openBlock(), _createElementBlock("p", { key: item }, _toDisplayString(item), 1))
                                    }), 128))
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.designs, (item) => {
                                      return (_openBlock(), _createElementBlock("p", { key: item }, _toDisplayString(item.name), 1))
                                    }), 128))
                                  ]))
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_el_table_column, {
                            prop: "designCost",
                            label: "Cost",
                            "min-width": "8"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.formatterDollars(scope.row.designCost)), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_table_column, { label: "Ship US" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_table_column, {
                            prop: "shipUsFirst",
                            label: "First",
                            "min-width": "8"
                          }, {
                            default: _withCtx((scope) => [
                              _createTextVNode(_toDisplayString(_ctx.formatterDollars(scope.row.shipUsFirst)), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_table_column, {
                            prop: "shipUsAdditional",
                            label: "Add",
                            "min-width": "8"
                          }, {
                            default: _withCtx((scope) => [
                              _createTextVNode(_toDisplayString(_ctx.formatterDollars(scope.row.shipUsAdditional)), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_table_column, { label: "Ship ROW" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_table_column, {
                            prop: "shipRowFirst",
                            label: "First",
                            "min-width": "8"
                          }, {
                            default: _withCtx((scope) => [
                              _createTextVNode(_toDisplayString(_ctx.formatterDollars(scope.row.shipRowFirst)), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_table_column, {
                            prop: "shipRowAdditional",
                            label: "Add",
                            "min-width": "8"
                          }, {
                            default: _withCtx((scope) => [
                              _createTextVNode(_toDisplayString(_ctx.formatterDollars(scope.row.shipRowAdditional)), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_table_column, {
                        width: "120px",
                        label: "Actions",
                        "min-width": "8"
                      }, {
                        default: _withCtx((scope) => [
                          _createVNode(_component_EditRowButton, {
                            onClick: ($event: any) => (_ctx.onEditVariant(scope.row))
                          }, null, 8, ["onClick"]),
                          _createVNode(_component_DeleteRowButton, {
                            onClick: ($event: any) => (_ctx.onDeleteVariant(scope.row))
                          }, null, 8, ["onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["data"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_EditVariantDialog, {
      ref: "editVariantDialog",
      title: `Edit variant`,
      designs: _ctx.designs
    }, null, 8, ["designs"])
  ], 64))
}