import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    class: "w-full",
    multiple: "",
    filterable: "",
    placeholder: "Please select a category"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (item) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: `category-${item._id}`,
          label: item.title,
          value: item._id
        }, null, 8, ["label", "value"]))
      }), 128))
    ]),
    _: 1
  }))
}