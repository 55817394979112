
import { defineComponent, onMounted, ref } from 'vue'
import useCatalogRepositories from '@/repositories/useCatalogRepositories'

export default defineComponent({
  components: {},
  props: {},
  setup() {
    const loading = ref(true)
    const tagList = ref()

    const { getAllCatalogTags } = useCatalogRepositories()

    const fetchAll = async () => {
      const data: any = await getAllCatalogTags()
      tagList.value = data ?? []
      loading.value = false
    }

    onMounted(() => {
      fetchAll()
    })

    return { loading, fetchAll, tagList }
  },
})
