import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_AttributeCatalogOptionDialog = _resolveComponent("AttributeCatalogOptionDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionList, (option) => {
      return (_openBlock(), _createBlock(_component_el_tag, {
        key: option.name,
        class: "mx-1",
        closable: "",
        "disable-transitions": false,
        onClose: ($event: any) => (_ctx.handleClose(option.name))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(option.name), 1)
        ]),
        _: 2
      }, 1032, ["onClose"]))
    }), 128)),
    _createVNode(_component_el_button, {
      class: "button-new-tag ml-1",
      size: "small",
      onClick: _ctx.showDialogAttribute
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(`+ New ${_ctx.type}`), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_AttributeCatalogOptionDialog, {
      title: `New ${_ctx.type}`,
      ref: "attributeCatalogOptionDialog",
      onSumitedAttribute: _ctx.onAfterSubmitAttribute
    }, null, 8, ["title", "onSumitedAttribute"])
  ]))
}