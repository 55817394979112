
import { computed, defineComponent, onMounted, ref } from 'vue'
import {
  formatterDollars,
  displayAttribute,
  displayInStockStatus,
} from '@/utils/formatter'
import EditRowButton from '@/components/common/buttons/EditRowButton.vue'
import DeleteRowButton from '@/components/common/buttons/DeleteRowButton.vue'
import CatalogOptions from '@/components/catalogs/CatalogOptions.vue'
import { useNotification } from '@/composables/useNotification'
import { generateVariantCode } from '@/utils/string'
import { Attribute } from '@/utils/variants'
import EditVariantDialog from '@/components/catalogs/update-components/EditVariantDialog.vue'
import ActionsButton from '@/components/common/buttons/ActionsButton.vue'

export interface UpdateAttribute {
  optionType: OptionType
  action: OptionAction
  option: Attribute
}

export enum OptionAction {
  add = 'add',
  remove = 'remove',
}

export enum OptionType {
  color = 'color',
  size = 'size',
}

export default defineComponent({
  components: {
    DeleteRowButton,
    EditRowButton,
    CatalogOptions,
    EditVariantDialog,
    ActionsButton,
  },
  props: {
    isEditable: {
      type: Boolean,
      required: true,
    },
    EditRowButtontable: {
      type: Boolean,
      default: false,
    },
    productCode: {
      type: String,
      default: '',
    },
    variants: {
      type: Array,
      default: () => [],
    },
    designs: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Array as () => Attribute[],
      default: () => [],
    },
    sizes: {
      type: Array as () => Attribute[],
      default: () => [],
    },
    catalog: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const isLoading = ref(true)
    const catalogVariants = ref<any[]>(props.variants)
    const colorList = ref<Attribute[]>(props.colors)
    const sizeList = ref<Attribute[]>(props.sizes)

    const filterColors = ref<string[]>([])
    const filterSizes = ref<string[]>([])

    const editMode = ref<boolean>(false)

    const { warning } = useNotification()

    onMounted(() => {
      // check editable and hide the actions
      if (!props.isEditable) {
        columns.pop()
      }
      isLoading.value = false
    })

    const isOptionReloading = ref<boolean>(false)
    const onChangeCatalogOption = async (data: UpdateAttribute) => {
      isOptionReloading.value = true

      const { action, optionType, option } = data
      const list = optionType === OptionType.color ? colorList : sizeList

      if (action === OptionAction.add) {
        const checkExisted = list.value.some(
          item => item.name?.toLowerCase() === option.name?.toLowerCase()
        )
        if (checkExisted) {
          warning('Existing attribute')
          isOptionReloading.value = false
          return
        }
        list.value.push(option)
      } else {
        list.value = list.value.filter(item => item.name !== option.name)
      }
      onChangeOption(data)

      setTimeout(() => (isOptionReloading.value = false), 0)
    }

    const onChangeOption = async (data: UpdateAttribute) => {
      const { action, optionType, option } = data
      if (action === OptionAction.add) {
        onAddOption(optionType, option)
      } else if (action === OptionAction.remove) {
        onDeleteOption(optionType, option)
      }
    }

    const onAddOption = async (type: OptionType, option: Attribute) => {
      const variant = catalogVariants.value.find(
        item => item[type].name === option.name
      )
      if (variant) {
        warning('Existing attribute')
        return
      }
      const isColor = type === OptionType.color
      const listOther = !isColor ? colorList : sizeList
      for (const item of listOther.value) {
        const color = isColor ? option : item
        const size = isColor ? item : option
        // create a new variant each attribute
        const newVariant = {
          catalogVariantCode: generateVariantCode(
            props.productCode,
            color,
            size
          ),
          color: color,
          size: size,
          inStock: true,
          cost: option.cost,
        }
        catalogVariants.value.push(newVariant)
      }
    }

    const onDeleteOption = async (type: OptionType, option: Attribute) => {
      const variantsNeedRemove = catalogVariants.value.filter(
        (item: any) => item[type].name === option.name
      )

      // remove all variants from the catalogVariants
      catalogVariants.value = catalogVariants.value.filter(
        (item: any) => !variantsNeedRemove.includes(item)
      )
    }

    const editVariantDialog = ref<InstanceType<typeof EditVariantDialog>>()
    const onEditVariant = async (variant: any) => {
      editVariantDialog.value?.toggle(variant)
    }

    const onDeleteVariant = async (variant: any) => {
      catalogVariants.value = catalogVariants.value.filter(
        (item: any) => item._id !== variant._id
      )
    }

    const selectedIds = computed(() => {
      return multipleSelection.value.map((variant: any) => variant._id)
    })

    let multipleSelection = ref([])
    const handleSelectionChange = (val: []) => {
      multipleSelection.value = val
    }

    const onSelectFilter = () => {
      console.log('filter')
    }

    const columns = [
      {
        title: 'Variant code',
        dataIndex: 'catalogVariantCode',
        minWidth: '15',
      },
      {
        title: 'Color',
        dataIndex: 'color',
        minWidth: '12',
      },
      {
        title: 'Size',
        dataIndex: 'size',
        minWidth: '12',
      },
      {
        title: 'Status',
        dataIndex: 'inStock',
        minWidth: '12',
      },
      {
        title: 'Cost',
        dataIndex: 'cost',
        minWidth: '10',
      },
    ]

    return {
      props,
      catalogVariants,
      isLoading,
      columns,
      displayAttribute,
      displayInStockStatus,
      formatterDollars,
      colorList,
      sizeList,
      onChangeCatalogOption,
      isOptionReloading,
      onChangeOption,
      onAddOption,
      onDeleteOption,
      onEditVariant,
      onDeleteVariant,
      editVariantDialog,
      editMode,
      multipleSelection,
      handleSelectionChange,
      selectedIds,
      filterColors,
      filterSizes,
      onSelectFilter,
    }
  },
})
