import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (!_ctx.loading)
    ? (_openBlock(), _createBlock(_component_el_select, {
        key: 0,
        class: "w-full",
        placeholder: "Tags",
        multiple: "",
        clearable: "",
        filterable: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagList, (item) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: item._id,
              label: item.name,
              value: item._id,
              clearable: ""
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}